import Axios from 'axios';
import moment from 'moment';

const FileSaver = require('file-saver');

const URL_END_POINT = '/cuentasCorrientes';

export const getCurrentAccountRequest = async (clientCode, dateTo, totals, pageSize, page) => {
    const response = await Axios.post(URL_END_POINT + '/search', {
        clientCode,
        dateTo,
        totals,
        pageSize,
        page,
    });
    return response.data;
};

export const downloadAccountingDocumentRequest = async (documentId, documentType, clientCode, documentDate) => {
    const response = await Axios.get(`${URL_END_POINT}/downloadDocument`, {
        params: {
        documentId, documentType, clientCode, documentDate
        },
        responseType: 'arraybuffer'
    });
    if (response && response.data) {
        FileSaver.saveAs(new Blob([response.data]), `${documentId}_${documentType}.pdf`, { autoBOM: true });
    }
}

export const downloadCompensatedMatches = async (clientCode, dateTo, totals, generatePDF) => {
    const response = await Axios.post(
        `${URL_END_POINT}/downloadCompensatedMatches`,
        {
            clientCode,
            dateTo,
            totals,
            generatePDF,
        },
        {
            responseType: 'arraybuffer',
        }
    );
    if (response && response.data) {
        const fileName = `${clientCode}_${moment().format('YYYYMMDD')}.pdf`;
        FileSaver.saveAs(new Blob([response.data]), fileName, { autoBOM: true });
    }
};
