import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import Router from 'next/router';
import Axios from 'axios';
import { userSignOutSuccess, showError, getUserConfigSuccess } from '../actions';
import { electronLogin, electronLogout } from '../electron/login';
import { getRandomColor } from '../Utils/userUtils';

import {
    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNIN_USER_SUCCESS,
} from '../constants/ActionsTypes';

import { ROLES, ROLE_TO_HOME_MAPPING } from '../constants/AuthConstants';

import {
    signInUserRequest,
    signOutUserRequest
} from '../api/Auth'

import ReactGA from 'react-ga4';
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

function* signInUser({ payload }) {
    const { username, password } = payload;

    function getUserId(user) {
        if (user && user.vendedor) {
            return user.vendedor.nombre;
        } else if (user && user.cliente) {
            return user.cliente.nombre;
        } else if (user && user.username) {
            return user.username;
        }
        return null;
    }

    try {
        let {login, user} = yield call(signInUserRequest, username, password);

        // make sure it's a clean login (i.e.: nothing left from some previous login)
        cleanupStorage();

        if (login.id) {
            localStorage.setItem('token', login.id);
            localStorage.setItem('userId', login.userId);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('avatarColor', getRandomColor());

            Axios.defaults.headers.common['authorization'] = login.id;

            // Sending user to Google Analytics.
            if (publicRuntimeConfig.GA_CODE) {
                const username = getUserId(user);

                ReactGA.set({
                    user_id: user.username,
                    iturriaUsername: username,
                    role: user && user.role ? user.role.name : null
                });
            }

            yield put(getUserConfigSuccess(user.userConfig));

            yield put({ type: SIGNIN_USER_SUCCESS, data: { userId: login.userId, token: login.id, user } });

            electronLogin();

            if (user.role.name === ROLES.ADMIN) {
                Router.push(ROLE_TO_HOME_MAPPING.admin);
            } else if(user.role.name === ROLES.REPORT) {
                Router.push(ROLE_TO_HOME_MAPPING.report);
            } else {
                Router.push(ROLE_TO_HOME_MAPPING['*']);
            }
        } else {
            //offline login
            localStorage.setItem('userId', user.id);
            localStorage.setItem('user', JSON.stringify(user));

            Axios.defaults.headers.common['Offline'] = user.id;

            yield put({ type: SIGNIN_USER_SUCCESS, data: { userId: user.id, user: user } });
            Router.push(ROLE_TO_HOME_MAPPING['*']);
        }

    } catch (err) {
        if (err.response && err.response.status == 401) {
            yield put(showError('Invalid username or password'));
        } else if (err.response && err.response.status == 405) {
            yield put(showError('user disabled'));
        } else if (err.response && err.response.status == 406) {
            yield put(showError('temporal access expired'));
        } else {
            yield put(showError('Connection error'));
        }
    }

}

function* signOutUser() {
    if (window.localStorage.getItem('token') || Axios.defaults.headers.common['Authorization']) {
        window.loggingOut = true;//flag to signal the intention, in case request fails
        try {
            yield call(signOutUserRequest);
        } catch (error) {
            // silently ignore error, most probably failed due to an expired token
        }
    }

    // Cleaning user info from Google Analytics.
    if (publicRuntimeConfig.GA_CODE) {
        ReactGA.set({
            user_id: '',
            iturriaUsername: '',
        });
    }

    cleanupStorage();

    delete Axios.defaults.headers.common['Authorization'];
    Router.push('/')
    yield put(userSignOutSuccess());

    electronLogout();
}

export function* signInUserSaga() {
    yield takeEvery(SIGNIN_USER, signInUser);
}

export function* signOutUserSaga() {
    yield takeLatest(SIGNOUT_USER, signOutUser);
}

export default function* rootSaga() {
    yield all([
        fork(signInUserSaga),
        fork(signOutUserSaga),
    ]);
}

export function cleanupStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('user');
    localStorage.removeItem('avatarColor');
    localStorage.removeItem('currentQuotationId');
    localStorage.removeItem('currentOrderId');
    localStorage.removeItem('userConfig');
    localStorage.removeItem('provisoryReceipts');
    localStorage.removeItem('provisoryReceiptsClientCode');
}
