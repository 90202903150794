import {
    CLEAR_RECEIPTS_DATA,
    STORE_RECEIPTS_DATA,
    UPLOAD_PROVISIONAL_RECEIPT,
    UPLOAD_PROVISIONAL_RECEIPT_SUCCESS,
} from '../constants/ActionsTypes';

export const uploadReceipt = (params) => {
    return {
        type: UPLOAD_PROVISIONAL_RECEIPT,
        payload: params,
    };
};

export const uploadReceiptSuccess = (data) => {
    return {
        type: UPLOAD_PROVISIONAL_RECEIPT_SUCCESS,
        payload: data,
    };
};

export const storeReceiptsData = (receiptsData, operation) => ({
    type: STORE_RECEIPTS_DATA,
    payload: receiptsData,
    operation: operation,
});

export const clearReceiptsData = () => {
    return {
        type: CLEAR_RECEIPTS_DATA,
    };
};
