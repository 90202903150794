import {
  GET_CURRENT_ACCOUNT,
  GET_CURRENT_ACCOUNT_SUCCESS,
} from '../constants/ActionsTypes'

const initialState = {
  results: []
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_ACCOUNT:
      return { ...state }
    case GET_CURRENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        results: action.data
      }
    default:
      return state
  }
}

export default rootReducer