import { all, call, fork, put, takeEvery, takeFirst } from 'redux-saga/effects';
import { getCurrentAccountSuccess } from '../actions/CurrentAccount';

import {
  GET_CURRENT_ACCOUNT,
  DOWNLOAD_ACCOUNTING_DOCUMENT,
  DOWNLOAD_COMPENSATED_MATCHES
} from '../constants/ActionsTypes';

import {
  getCurrentAccountRequest,
  downloadAccountingDocumentRequest,
  downloadCompensatedMatches
} from '../api/CurrentAccount';

function* getCurrentAccount({ payload }) {

  const { clientCode, dateTo, totals, pageSize, page } = payload;
  try {
    const response = yield call(getCurrentAccountRequest, clientCode, dateTo, totals, pageSize, page);
    if (response) {
      yield put(getCurrentAccountSuccess(response));
    }
  } catch (error) {
    // handled in interceptor
  }
}

function* downloadAccountingDocument({ payload }) {

  const { documentId, documentType, clientCode, documentDate } = payload;
  try {
    const response = yield call(downloadAccountingDocumentRequest, documentId, documentType, clientCode, documentDate);
  } catch (error) {
    // handled in interceptor
  }
}

function* downloadCompensatedMatchesRequest({ payload }) {
  const { clientCode, dateTo, totals, generatePDF } = payload;
  try {
    const response = yield call(downloadCompensatedMatches, clientCode, dateTo, totals, generatePDF);
  } catch (error) {
    // handled in interceptor
  }
}

export function* getCurrentAccountSaga() {
  yield takeEvery(GET_CURRENT_ACCOUNT, getCurrentAccount);
}

export function* downloadAccountingDocumentSaga() {
  yield takeEvery(DOWNLOAD_ACCOUNTING_DOCUMENT, downloadAccountingDocument);
}

export function* downloadCompensatedMatchesSaga() {
  yield takeEvery(DOWNLOAD_COMPENSATED_MATCHES, downloadCompensatedMatchesRequest);
}

export default function* rootSaga() {
  yield all([
    fork(getCurrentAccountSaga),
    fork(downloadAccountingDocumentSaga),
    fork(downloadCompensatedMatchesSaga),
  ]);
}