import { all, call, fork, put, takeEvery, takeLatest, throttle, select } from 'redux-saga/effects';

import {
    GET_CATALOG_ENTRY,
    GET_CATALOG_ENTRY_SUCCESS,
    GET_CATALOG_ENTRY_ERROR,
    SEARCH_CATALOGS_ENTRIES,
    ADD_UPDATE_CATALOG_ENTRY,
    ADD_UPDATE_CATALOG_ENTRY_ERROR,
    AUTOCOMPLETE_CATALOGS_ENTRIES,
    GET_PRODUCTS_BY_LINEA_CATALOGO,
    GET_PRODUCTS_BY_LINEA_CATALOGO_SUCCESS,
    GET_PRODUCTS_BY_LINEA_CATALOGO_ERROR,
    GET_PRODUCTS_BY_VENDOR_ID_SUCCESS,
    GET_PRODUCTS_BY_VENDOR_ID_ERROR,
    GET_PRODUCTS_BY_VENDOR_ID,
} from '../constants/ActionsTypes';

import {
    getCatalogEntry,
    searchCatalogEntries,
    addCatalogEntry,
    addRemoveCatalogSegmentsEntry,
    addRemoveCatalogLinesEntry,
    getProductsByCatalogLineRequest,
    getProductsByVendorIdRequest,
} from '../api/CatalogEntries';

import {
    searchCatalogEntriesSuccess,
    addCatalogEntrySuccess,
    autocompleteCatalogEntriesSuccess
} from '../actions/CatalogEntries';

export const getCommonState = (state) => state.common;

function* callGetCatalogEntry({ payload }) {
    const { catalogEntryId } = payload;

    try {
        const response = yield call(getCatalogEntry, catalogEntryId);
        yield put({ type: GET_CATALOG_ENTRY_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_CATALOG_ENTRY_ERROR, error });
    }
}


function* searchCatalogEntriesRequest({ payload }) {
    const params = yield select(getCommonState);
    const { AdminActionBar } = params;
    
    if(AdminActionBar.showRemove){
        payload.borrado = AdminActionBar.showRemove;
    }

    try {
        const catalogs = yield call(searchCatalogEntries, payload);
        yield put(searchCatalogEntriesSuccess(catalogs));
    } catch (error) {
    }
}

function* addCatalogEntryRequest({ payload }) {
    try {
        //Guardo la Linea Catalogo

        const catalogEntry = yield call(addCatalogEntry, payload.params);
        //Guardo los Segmentos de la Linea Catalogo       

        const catalogLineSegment = yield call(addRemoveCatalogSegmentsEntry, {
            "lineaCatalogoId": catalogEntry.id,
            "segmentos": payload.params.segmentos
        });
        const catalogLineLine = yield call(addRemoveCatalogLinesEntry, {
            "lineaCatalogoId": catalogEntry.id,
            "lineas": payload.params.lineas
        });



        yield put(addCatalogEntrySuccess(catalogEntry));
    } catch (error) {
        yield put({ type: ADD_UPDATE_CATALOG_ENTRY_ERROR, error });

    }
}

function* autocompleteCatalogEntriesRequest({ payload }) {

   const params = yield select(getCommonState);
    const { AdminActionBar } = params;
    
    let query = {
        'filtro': payload,
        'page': 1,
        'pageSize': 10
    }

    if(AdminActionBar.showRemove){
        query.borrado = AdminActionBar.showRemove;
    } 
    
    try {
        const catalogs = yield call(searchCatalogEntries, query);
        yield put(autocompleteCatalogEntriesSuccess(catalogs));
    } catch (error) {
    }
}

function* getProductsByLineaCatalogo({ payload }) {    
    try {
        const response = yield call(getProductsByCatalogLineRequest, payload);        
        yield put({ type: GET_PRODUCTS_BY_LINEA_CATALOGO_SUCCESS, data: response.response });
    } catch (error) {
        yield put({ type: GET_PRODUCTS_BY_LINEA_CATALOGO_ERROR, error });
    }
}

function* getProductsByVendorId({ payload }) {
    try {
        const response = yield call(getProductsByVendorIdRequest, payload);        
        yield put({ type: GET_PRODUCTS_BY_VENDOR_ID_SUCCESS, data: response.response });
    } catch (error) {
        yield put({ type: GET_PRODUCTS_BY_VENDOR_ID_ERROR, error });
    }
}

export function* searchCatalogEntriesSaga() {
    yield takeEvery(SEARCH_CATALOGS_ENTRIES, searchCatalogEntriesRequest);
}

export function* addCatalogEntrySaga() {
    yield takeEvery(ADD_UPDATE_CATALOG_ENTRY, addCatalogEntryRequest);
}

export function* getCatalogEntrySaga() {
    yield takeLatest(GET_CATALOG_ENTRY, callGetCatalogEntry);
}

export function* autocompleteCatalogEntriesSaga() {
    yield throttle(1000, AUTOCOMPLETE_CATALOGS_ENTRIES, autocompleteCatalogEntriesRequest);
}

export function* getProductsByLineaCatalogoSaga() {
    yield takeEvery(GET_PRODUCTS_BY_LINEA_CATALOGO, getProductsByLineaCatalogo);
}

export function* getProductsByVendorIdSaga() {
    yield takeEvery(GET_PRODUCTS_BY_VENDOR_ID, getProductsByVendorId);
}

export default function* rootSaga() {
    yield all([
        fork(getCatalogEntrySaga),
        fork(searchCatalogEntriesSaga),
        fork(autocompleteCatalogEntriesSaga),
        fork(addCatalogEntrySaga),
        fork(getProductsByLineaCatalogoSaga),
        fork(getProductsByVendorIdSaga),
    ]);
}