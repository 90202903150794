import {
    DOWNLOAD_ACCOUNTING_DOCUMENT,
    DOWNLOAD_COMPENSATED_MATCHES,
    GET_CURRENT_ACCOUNT_SUCCESS,
    GET_CURRENT_ACCOUNT,
} from '../constants/ActionsTypes';

export const getCurrentAccount = (
    clientCode,
    dateTo,
    totals,
    pageSize,
    page
) => {
    return {
        type: GET_CURRENT_ACCOUNT,
        payload: { clientCode, dateTo, totals, pageSize, page },
    };
};

export const getCurrentAccountSuccess = (result) => {
    return {
        type: GET_CURRENT_ACCOUNT_SUCCESS,
        data: result,
    };
};

export const downloadAccountingDocument = (documentId, documentType, clientCode, documentDate) => {
    return {
        type: DOWNLOAD_ACCOUNTING_DOCUMENT,
        payload: { documentId, documentType, clientCode, documentDate }
    }
};

export const downloadCompensatedMatches = (
    clientCode,
    dateTo,
    totals,
    generatePDF
) => {
    return {
        type: DOWNLOAD_COMPENSATED_MATCHES,
        payload: { clientCode, dateTo, totals, generatePDF },
    };
};
