import {all, call, fork, put, takeEvery, throttle, select, takeLatest, delay} from 'redux-saga/effects';
import getConfig from 'next/config'

import {
    GET_PRODUCTS,
    GET_PRODUCT,
    GET_PRODUCTS_ERROR,
    GET_PRODUCT_ERROR,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCT_SUCCESS,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_ERROR,
    SEARCH_PRODUCTS,
    AUTOCOMPLETE_PRODUCTS,
    SEARCH_CATALOG_PRODUCTS,
    SEARCH_CATALOG_PRODUCTS_SUCCESS,
    SEARCH_CATALOG_PRODUCTS_ERROR,
    GET_PRODUCT_BY_CODE,
    GET_PRODUCT_BY_CODE_SUCCESS,
    GET_PRODUCTS_BY_IDS,
    GET_PRODUCTS_BY_IDS_SUCCESS,
    GET_PRODUCT_PRICE_CLIENT,
    SHOW_ONE_PRODUCT,
    SHOW_ONE_PRODUCT_SUCCESS,
    GET_APPLICATIONS_BY_PRODUCT_CODE,
    GET_APPLICATION_BY_PRODUCT_CODE_LOADING,
    GET_APPLICATIONS_BY_PRODUCT_ID,
    GET_PRODUCT_PRICE_BY_CLIENT_LOADING,
    DELETE_APPLICATION_FROM_PRODUCT,
    CREATE_APPLICATION_FROM_PRODUCT,
    EDIT_APPLICATION_FROM_PRODUCT
} from '../constants/ActionsTypes';

import {
    searchProductsSuccess,
    autocompleteProductsSuccess,
    getProductPriceByClientSuccess,
    getProductPriceByClientError,
    getApplicationByProductCodeSuccess,
    getApplicationByProductIdSuccess,
    deleteApplicationFromProductSuccess,
    createApplicationFromProductSuccess,
    editApplicationFromProductSuccess
} from '../actions/Products';

import {
    getProductsRequest,
    getProductRequest,
    addImagesRequest,
    searchProductsRequest,
    searchCatalogProductsRequest,
    getProductByCode,
    getProductsByIds,
    getProductPriceByClient,
    getApplicationByProductCode,
    getApplicationByProductId,
    deleteApplicationForProduct,
    createApplicationForProduct,
    editApplicationForProduct
} from '../api/Products';

export const getCommonState = (state) => state.common;

const { publicRuntimeConfig } = getConfig();

function* getProducts() {
    try {
        const response = yield call(getProductsRequest);
        yield put({ type: GET_PRODUCTS_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_PRODUCTS_ERROR, error });
    }
}

function* getProduct({ payload }) {
    const { productId } = payload;
    try {
        const response = yield call(getProductRequest, productId);

        yield put({ type: GET_PRODUCT_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: GET_PRODUCT_ERROR, error });
    }
}

function* updateProduct({ payload }) {
    const { productId, updatable } = payload;
    try {
        yield call(addImagesRequest, productId, updatable);
        yield put({ type: UPDATE_PRODUCT_SUCCESS });
    } catch (error) {
        yield put({ type: UPDATE_PRODUCT_ERROR });
    }
}

function* searchProducts({ payload }) {
    yield delay(publicRuntimeConfig.delayTyping);
    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;

    if (showRemove) {
        payload.borrado = showRemove;
    }

    try {
        const {data} = yield call(searchProductsRequest, payload);
        yield put(searchProductsSuccess(data));
    } catch (error) {
    }
}

function* autocompleteProducts({ payload }) {
    const params = yield select(getCommonState);
    const { showRemove } = params.AdminActionBar;

    let query = {
        'filtro': payload,
        'page': 1,
        'pageSize': 10
    }

    if (showRemove) {
        query.borrado = showRemove;
    }

    try {
        const response = yield call(searchProductsRequest, query);
        yield put(autocompleteProductsSuccess(response.data));
    } catch (error) {
    }
}

function* searchCatalogProducts({ payload }) {
    yield delay(publicRuntimeConfig.delayTyping);
    try {
        const catalog = yield select((state) => state.catalog);
        const params = {
            ...payload,
            segmentId: (catalog.catalogSegment) ? catalog.catalogSegment.id : undefined
        }
        const response = yield call(searchCatalogProductsRequest, params);
        yield put({ type: SEARCH_CATALOG_PRODUCTS_SUCCESS, data: response.data });
    } catch (error) {
        yield put({ type: SEARCH_CATALOG_PRODUCTS_ERROR, error });
    }
}

function* getProductByCodeRequest({ payload }) {
    try {
        const response = yield call(getProductByCode, payload);
        yield put({ type: GET_PRODUCT_BY_CODE_SUCCESS, data: response.data });
    } catch (error) {
        //
    }
}

function* getProductsByIdsRequest({ payload }) {
    try {
        const response = yield call(getProductsByIds, payload);
        yield put({ type: GET_PRODUCTS_BY_IDS_SUCCESS, data: response.data.response.products });
    } catch (error) {
        //
    }
}

function* getProductPriceByClientRequest({ payload }) {
    try {
        yield put({ type: GET_PRODUCT_PRICE_BY_CLIENT_LOADING });

        const response = yield call(getProductPriceByClient, payload);
        yield put(getProductPriceByClientSuccess(response.data));

    } catch (error) {
        yield put(getProductPriceByClientError(error));
    }
}

function* showOneProductbyCodeRequest({ payload }) {
    try {
        const response = yield call(getProductByCode, payload);
        yield put({ type: SHOW_ONE_PRODUCT_SUCCESS, data: response.data, show: true });
    } catch (error) {
        //
    }
}

function* getApplicationByProductCodeRequest({ payload }) {
    try {
        // Establish the begin of call
        yield put({ type: GET_APPLICATION_BY_PRODUCT_CODE_LOADING });

        const response = yield call(getApplicationByProductCode, payload);
        yield put(getApplicationByProductCodeSuccess(response.data.response.categorias));

    } catch (error) {
        //
    }
}

function* getApplicationByProductIdRequest({ payload }) {
    try {
        const response = yield call(getApplicationByProductId, payload);
        yield put(getApplicationByProductIdSuccess(response.data.response.categorias));
    } catch (error) {
        //
    }
}

function* deleteApplicationForProductRequest({ payload }) {
    try {
        yield call(deleteApplicationForProduct, payload);
        yield put(deleteApplicationFromProductSuccess());
    } catch (error) {
    }
}

function* createApplicationForProductRequest({ payload }) {
    try {
        yield call(createApplicationForProduct, payload);
        yield put(createApplicationFromProductSuccess());
    } catch (error) {
    }
}

function* editApplicationForProductRequest({ payload }) {
    try {
        yield call(editApplicationForProduct, payload);
        yield put(editApplicationFromProductSuccess());
    } catch (error) {
    }
}

export function* searchProductsSaga() {
    yield takeLatest(SEARCH_PRODUCTS, searchProducts);
}

export function* getProductsSaga() {
    yield takeEvery(GET_PRODUCTS, getProducts);
}

export function* getProductSaga() {
    yield takeEvery(GET_PRODUCT, getProduct);
}

export function* updateProductSaga() {
    yield takeEvery(UPDATE_PRODUCT, updateProduct);
}

export function* autocompleteProductsSaga() {
    yield throttle(1000, AUTOCOMPLETE_PRODUCTS, autocompleteProducts);
}

export function* searchCatalogProductsSaga() {
    yield takeLatest(SEARCH_CATALOG_PRODUCTS, searchCatalogProducts);
}

export function* getProductByCodeSaga() {
    yield takeEvery(GET_PRODUCT_BY_CODE, getProductByCodeRequest);
}

export function* getProductsByIdsSaga() {
    yield takeEvery(GET_PRODUCTS_BY_IDS, getProductsByIdsRequest);
}

export function* getProductPriceByClientSaga() {
    yield takeLatest(GET_PRODUCT_PRICE_CLIENT, getProductPriceByClientRequest);
}

export function* showOneProductSaga() {
    yield takeEvery(SHOW_ONE_PRODUCT, showOneProductbyCodeRequest);
}

export function* getApplicationByProductCodeSaga() {
    yield takeLatest(GET_APPLICATIONS_BY_PRODUCT_CODE, getApplicationByProductCodeRequest);
}

export function* getApplicationByProductIdSaga() {
    yield takeEvery(GET_APPLICATIONS_BY_PRODUCT_ID, getApplicationByProductIdRequest);
}

export function* deleteApplicationForProductSaga() {
    yield takeEvery(DELETE_APPLICATION_FROM_PRODUCT, deleteApplicationForProductRequest);
}

export function* createApplicationForProductSaga() {
    yield takeEvery(CREATE_APPLICATION_FROM_PRODUCT, createApplicationForProductRequest);
}

export function* editApplicationForProductSaga() {
    yield takeEvery(EDIT_APPLICATION_FROM_PRODUCT, editApplicationForProductRequest);
}

export default function* rootSaga() {
    yield all([
        fork(getProductsSaga),
        fork(getProductSaga),
        fork(updateProductSaga),
        fork(searchProductsSaga),
        fork(autocompleteProductsSaga),
        fork(searchCatalogProductsSaga),
        fork(getProductByCodeSaga),
        fork(getProductsByIdsSaga),
        fork(getProductPriceByClientSaga),
        fork(showOneProductSaga),
        fork(getApplicationByProductCodeSaga),
        fork(getApplicationByProductIdSaga),
        fork(deleteApplicationForProductSaga),
        fork(createApplicationForProductSaga),
        fork(editApplicationForProductSaga)
    ]);
}
