import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import {
  UPLOAD_PROVISIONAL_RECEIPT,
  GET_TAX_WITHHOLDINGS_TYPES
} from '../constants/ActionsTypes';

import {
  uploadReceipt,
  getTaxWithholdingsTypes
} from '../api/provisionalReceipts';

import {
  uploadReceiptSuccess,
  getTaxWithholdingsTypesSuccess
} from '../actions';

function* uploadReceiptRequest({payload}) {
  try {
    const {data} = yield call(uploadReceipt, payload);
    yield put(uploadReceiptSuccess(data));
  } catch (error) {
  }
}

function* getTaxWithholdingsTypesRequest() {
  try {
    const {data} = yield call(getTaxWithholdingsTypes);
    yield put(getTaxWithholdingsTypesSuccess(data));
  } catch (error) {
  }
}

export function* uploadReceiptSaga() {
  yield takeLatest( UPLOAD_PROVISIONAL_RECEIPT , uploadReceiptRequest);
}

export function* getTaxWithholdingsTypesSaga() {
  yield takeLatest( GET_TAX_WITHHOLDINGS_TYPES, getTaxWithholdingsTypesRequest);
}

export default function* rootSaga() {
  yield all([
    fork(uploadReceiptSaga),
    fork(getTaxWithholdingsTypesSaga),
  ]);
}
